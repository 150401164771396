import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {ConfirmationModalWindow} from '../confirmation-modal-window/ConfirmationModalWindow';
import {FormattedDate} from 'react-intl';

import './styles.scss';
import {IJournalDialogue} from '../../redux/journal/interfaces';
import JournalModalWindow from '../journal-modal-window';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import {
  editJournal,
  journalAsyncActions,
  removeJournal
} from '../../redux/pageAsyncActions';

type Props = {
  card: IJournalDialogue;
  onClick: () => void;
}

export const JournalCard = ({
  card,
  onClick
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState<IJournalDialogue>(card);
  const [isShowEditDialogue, setShowEditDialogue] = useState<boolean>(false);
  const [isShowDeleteDialogue, setShowDeleteDialogue] = useState<boolean>(false);

  function handleOpenEditDialogueModal() {
    setShowEditDialogue(true);
  }

  useEffect(() => {
    setCardData(card);
  }, [card]);

  function handleEditDialogue(newJournal: IJournalDialogue) {
    dispatch(journalAsyncActions[editJournal](newJournal));
    setCardData(newJournal);
    setShowEditDialogue(false);
  }

  function handleDeleteDialogue() {
    dispatch(journalAsyncActions[removeJournal](cardData));
  }

  function handleCloseDialogue() {
    setShowEditDialogue(false);
  }

  function handleOpenDeleteDialogue() {
    setShowDeleteDialogue(true);
  }

  function handleCloseDeleteDialogue() {
    setShowDeleteDialogue(false);
  }

  function handleClick() {
    onClick();
  }

  function renderBasicCard() {
    return <div className="journal-area">
      <div className="journal-area__card-place">
        <div className="journal-area__card">
          <div className="journal-area__title">
            <div className="journal-area__title-wrapper">
              <button className="journal-area__title__text" onClick={handleClick}>{cardData.dialogName}</button>
              <div className="journal-area__title__date">{cardData.dialogUserDate != null
                && <FormattedDate
                  value={cardData.dialogUserDate}
                  year="numeric"
                  month="long"
                  hour="numeric"
                  minute="numeric"
                  day="2-digit"
                />}</div>
            </div>
          </div>
          <div className="journal-area__title-percent" style={{
            background: `linear-gradient(to left, #f2f2f2 ${100 - cardData.percentage}%, #EBA378 ${100 - cardData.percentage}%)`
          }}>
          </div>
        </div>
        <div className="journal-area__bottom">
          <div className="journal-area__btn">
            <GritxButton
              title="Rename"
              variant={ButtonVariant.Outline}
              disabled={false}
              className="journal-area__button"
              onClick={handleOpenEditDialogueModal}
            />
          </div>
          <div className="journal-area__btn">
            <GritxButton
              title="Delete"
              variant={ButtonVariant.Outline}
              disabled={cardData.percentage === 100}
              className="journal-area__button"
              onClick={handleOpenDeleteDialogue}
            />
          </div>
        </div>
      </div>
      <JournalModalWindow
        journalCard={card}
        title={'Edit the journal'}
        onSave={handleEditDialogue}
        onClose={handleCloseDialogue}
        show={isShowEditDialogue}
      />
      <ConfirmationModalWindow
        title={'Are you sure you want to delete the journal?'}
        onSave={handleDeleteDialogue}
        onClose={handleCloseDeleteDialogue}
        show={isShowDeleteDialogue}
      />
    </div>;
  }

  return renderBasicCard();
};
